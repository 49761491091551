import Api from './api.service';

export default {
  get: (params) => Api.get('clients', { params }),
  search: (term = '') =>
    Api.get(`clients?filter[name_email_or_document]=${term}`),
  getId: (id) => Api.get(`clients/${id}`),
  store: (data) => Api.post('clients', data),
  update: (id, data) => Api.put(`clients/${id}`, data),
  destroy: (id) => Api.delete(`clients/${id}`),
  import: (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return Api.post('clients/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
  },
  export: (params) => {
    return Api.get('clients/export', { params });
  },
};
