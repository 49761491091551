<template>
  <div class="patient-form-view">
    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>
    <app-header icon="users" :title="pageTitle" goes-back></app-header>

    <section v-if="!isLoading">
      <div class="card">
        <div class="card-content">
          <form @submit="(event) => event.preventDefault()">
            <div class="row">
              <div class="col-11 mx-auto mx-lg-0 col-lg-3">
                <app-input
                  label="Nome"
                  v-model="patient.name"
                  :errors="errors.name"
                  :disabled="!isEditable"
                  is-required
                >
                </app-input>
              </div>

              <div class="col-11 mx-auto mx-lg-0 col-lg-3">
                <app-input
                  label="CPF"
                  v-model="patient.cpf"
                  :errors="errors.cpf"
                  v-mask="'###.###.###-##'"
                  :disabled="!isEditable"
                  is-required
                  @blur="testaCPF"
                ></app-input>
              </div>

              <div class="col-11 mx-auto mx-lg-0 col-lg-3">
                <app-input
                  label="E-mail"
                  v-model="patient.email"
                  type="email"
                  autocomplete="email"
                  :errors="errors.email"
                  :disabled="!isEditable"
                  is-required
                ></app-input>
              </div>

              <div class="col-11 mx-auto mx-lg-0 col-lg-3">
                <app-input
                  label="Telefone"
                  v-model="patient.phone"
                  type="tel"
                  v-mask="phoneMask"
                  maxlength="15"
                  :errors="errors.phone"
                  :disabled="!isEditable"
                  is-required
                ></app-input>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-11 mx-auto mx-lg-0 col-lg-3">
                <b-field class="required" label="Data de nascimento">
                  <app-input
                    class="input-custom"
                    v-model.trim="patient.birthday"
                    placeholder="Digite a data."
                    v-mask="'##/##/####'"
                    :disabled="!isEditable"
                    is-required
                  ></app-input>
                </b-field>
              </div>

              <div class="col-11 mx-auto mx-lg-0 col-lg-3">
                <b-field class="required" label="Gênero">
                  <b-select
                    class="select-custom"
                    v-model="patient.gender"
                    placeholder="Selecionar"
                    :disabled="!isEditable"
                    expanded
                  >
                    <option
                      v-for="gender in genders"
                      :value="gender.char"
                      :key="gender.char"
                    >
                      {{ gender.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>

              <div class="col-11 mx-auto mx-lg-0 col-lg-6"></div>
            </div>

            <div class="row mt-2">
              <div class="col-11 mx-auto mx-lg-0 col-lg-3">
                <app-input
                  label="CEP"
                  type="text"
                  v-model="patient.cep"
                  @input="onSearchCep"
                  maxlength="9"
                  v-mask="'#####-###'"
                  :errors="errors.cep"
                  :disabled="!isEditable || isSearchCep"
                  is-required
                ></app-input>
              </div>

              <div class="col-11 mx-auto mx-lg-0 col-lg-6">
                <app-input
                  label="Rua"
                  v-model="patient.street"
                  type="text"
                  :errors="errors.street"
                  :disabled="!isEditable || isSearchCep"
                  is-required
                ></app-input>
              </div>

              <div class="col-11 mx-auto mx-lg-0 col-lg-3">
                <app-input
                  label="Número"
                  v-model="patient.number"
                  type="tel"
                  :errors="errors.number"
                  :disabled="!isEditable"
                  is-required
                ></app-input>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-11 mx-auto mx-lg-0 col-lg-4">
                <app-input
                  label="Complemento"
                  v-model="patient.complement"
                  type="text"
                  :errors="errors.complement"
                  :disabled="!isEditable || isSearchCep"
                ></app-input>
              </div>

              <div class="col-11 mx-auto mx-lg-0 col-lg-4">
                <app-input
                  label="Bairro"
                  v-model="patient.neighborhood"
                  type="tel"
                  :errors="errors.neighborhood"
                  :disabled="!isEditable || isSearchCep"
                  is-required
                ></app-input>
              </div>

              <div class="col-11 mx-auto mx-lg-0 col-lg-4">
                <app-input
                  label="Cidade"
                  v-model="patient.city_name"
                  :errors="errors.city"
                  :disabled="!isEditable || isSearchCep"
                  is-required
                ></app-input>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 has-text-right">
                <div class="col-11 col-lg-2 mx-auto mx-lg-0 float-lg-right">
                  <b-button
                    class="button-rounded remove-focus btn-success border-0 px-3"
                    @click="onSubmit"
                    :loading="isSaving"
                    :disabled="isLoading || isSaving"
                    type="is-primary"
                    block
                  >
                    Salvar
                  </b-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import StatesService from '@/services/states.service';
import CitiesService from '@/services/cities.service';
import CepService from '@/services/cep.service';
import PatientsService from '@/services/patients.service';

import Prescription from '@/modals/Prescription.vue';
import MedicalRecord from '@/modals/MedicalRecord.vue';

import MedicalRecordModelService from '@/services/medical-records.service';

import MASKS from '@/constants/masks.constant';
import * as moment from 'moment';
import { mapGetters } from 'vuex';
import AppInput from '../../components/inputs/AppInput.vue';

export default {
  components: {
    AppInput,
  },

  beforeMount() {
    this.patientId = Number(this.$route.params.id);
    this.patientId && this.loadPatient(this.patientId);
  },

  computed: {
    ...mapGetters(['memedToken']),
    pageTitle() {
      if (!this.isEditable) return 'Visualizar Cliente';
      return `${this.patientId ? 'Editar' : 'Cadastrar'} Cliente`;
    },
    isEditable() {
      if (!this.patientId) return true;
      return this.patient.is_editable;
    },
    phoneMask() {
      if (this.patient.phone && this.patient.phone.length > 14) {
        return '(##) #####-####';
      } else {
        return '(##) ####-####';
      }
    },
  },

  data: () => ({
    MASKS: MASKS,
    patientId: null,
    isLoading: false,
    isSaving: false,
    isSearchCep: false,
    genders: [
      { char: 'm', name: 'Masculino' },
      { char: 'f', name: 'Feminino' },
    ],
    patient: {
      cpf: null,
      email: null,
      birthday: null,
      cep: null,
      street: null,
      number: null,
      complement: null,
      neighborhood: null,
      city_id: null,
      city_name: null,
      gender: null,
    },
    errors: {},
    viewPrescription: false,
  }),

  methods: {
    statesGetter: StatesService.search,
    citiesGetter: CitiesService.search,
    citySetter: CitiesService.getId,

    loadPatient(id) {
      this.isLoading = true;

      PatientsService.getId(id)
        .then(({ data }) => {
          this.patient = {
            ...data,
            cpf: data.cpf.replace(
              /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
              '$1.$2.$3-$4'
            ),
            phone: data.phone.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3'),
            cep: (data.cep || '').replace(/^(\d{2})(\d{3})(\d{3})/, '$1.$2-$3'),
            birthday: moment(data.birthday).format('DD/MM/YYYY'),
            email: data.user.email,
          };
        })
        .catch(() => this.$buefy.snackbar.open('Erro ao carregar cliente.'))
        .finally(() => (this.isLoading = false));
    },

    testaCPF() {
      if (!this.patient.cpf) return;

      let cpf = this.patient.cpf.replace(/[^\d]+/g, '').trim();

      const regex = new RegExp(
        `^(1{11}|2{11}|3{11}|4{1}|5{11}|6{11}|7{11}|8{11}|9{11})$`
      );

      if (cpf?.length < 11 || regex.test(cpf)) {
        this.$buefy.snackbar.open({
          message: 'Cpf inválido',
          type: 'is-danger',
          duration: 5000,
          pauseOnHover: true,
        });
        return;
      }
    },

    onSubmit() {
      this.isSaving = true;
      this.errors = {};

      const { cpf, cep, phone, birthday, number } = this.patient;
      const data = {
        ...this.patient,
        cpf: cpf.replace(/\D/g, ''),
        cep: (cep || '').replace(/\D/g, ''),
        phone: phone?.replace(/\D/g, ''),
        birthday: moment(
          birthday?.split('/').reverse().join('-')
        ).toISOString(),
        number: number,
      };

      const promise = this.patientId
        ? PatientsService.update(this.patientId, data)
        : PatientsService.store(data);

      promise
        .then(() => {
          this.$buefy.snackbar.open('Cliente salvo com sucesso.');
          this.$router.go(-1);
        })
        .catch((error) => {
          const { status, data } = error;

          let message =
            'Erro inesperado, tente novamente ou vale com um administrador do sistema';

          if ([422, 400].includes(status)) {
            message =
              data.errors == typeof 'string'
                ? data.errors
                : 'Verifique os campos';
          }

          this.notifyError(data, message); // src/mixin/CustomError
        })
        .finally(() => (this.isSaving = false));
    },

    openRecord(record = null) {
      this.$buefy.modal.open({
        parent: this,
        component: MedicalRecord,
        hasModalCard: true,
        width: 3000,
        trapFocus: true,
        props: {
          record,
          patientId: this.patientId,
        },
        events: {
          close: (data) => data && this.$refs.recordsTable.loadPatientRecords(),
        },
      });
    },

    openPrescription(prescription = null) {
      if (prescription && prescription.by_memed) {
        window.MdHub.command.send(
          'plataforma.prescricao',
          'viewPrescription',
          prescription.memed_prescription_id
        );
      } else {
        this.$buefy.modal.open({
          parent: this,
          component: Prescription,
          width: 3000,
          hasModalCard: true,
          trapFocus: true,
          props: {
            prescription,
            patientId: this.patientId,
          },
          events: {
            close: (data) =>
              data && this.$refs.prescriptionsTable.loadPrescriptions(),
          },
        });
      }
    },

    onDeleteClick(recordId) {
      this.$buefy.dialog.confirm({
        message: 'Deseja realmente deletar esse prontuário?',
        onConfirm: () => this.deleteRecord(recordId),
      });
    },

    onSearchCep(cep) {
      if (!cep || !this.isEditable) return;

      cep = cep.replace(/\D/g, '');
      if (cep.length < 8) return;

      this.isSearchCep = true;

      CepService.getCepInfo(cep)
        .then(
          ({
            data: { bairro, logradouro, complemento, city_id, city_name },
          }) => {
            this.patient = {
              ...this.patient,
              street: logradouro,
              neighborhood: bairro,
              complement: complemento,
              city_id,
              city_name,
            };
          }
        )
        .catch(() =>
          this.$buefy.snackbar.open({
            message: 'Cep não encontrado',
            type: 'is-danger',
            duration: 5000,
          })
        )
        .finally(() => (this.isSearchCep = false));
    },

    deleteRecord(id) {
      MedicalRecordModelService.deleteRecord(id).then(() => {
        this.$buefy.snackbar.open('Prontuário deletado com sucesso.');
        this.$refs.recordsTable.loadPatientRecords();
      });
    },

    reloadPrescriptionsTable() {
      this.$refs.prescriptionsTable.loadPrescriptions();
    },

    canViewPrescription(value) {
      this.viewPrescription = value;
    },

    isAfter30Minutes(schedule) {
      const current_date = moment();

      return moment(schedule.start).diff(current_date, 'minutes') >= 30;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
